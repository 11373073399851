import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { styled } from "@mui/system";

import Navbar from "./Navbar";
import SuccessPage from "./pages/SuccessPage";
import Home from "./Home";
import AddStore from "./Store/AddStore";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import ProductImport from "./ProductImport";
import UserDashboard from "./Components/Dashboard/UserDashboard";

import { UserAuthContextProvider } from "./context/userAuthContext";
import Footer from "./Footer";
import { Chat } from "@mui/icons-material";
import AddProject from "./Components/Projects/AddProject";
import ProjectDashboard from "./Components/Projects/ProjectDashboard";
import MyProjects from "./Components/Projects/MyProjects";
import Contact from "./Home/Contact";
import ProductDetails from "./ProductDetails";
import ProductList from "./ProductList";
import Cart from "./Cart.js";
import Dashboard from "./Dashboard.js";
import Radios from "../src/pages/Radiocomunicacion/Radios.js";
import RadiosConvencionales from "./pages/Radiocomunicacion/RadiosConvencionales.js";
import Algoliaimport from "./Algoliaimport.js";
import NuevoInventario from "./Components/Inventario/NuevoInventario.js";
import ProtectedRoute from "./Components/ProtectedRoute"; // Importa el componente de ruta protegida
import AdminDashboard from "./Components/AdminDashboard/AdminDashboad.js";
import Inventario from "./Components/Inventario/Inventario.js";
import ImportCSV from "./Components/ImportCSV.js";
import UploadInspectionPhotos from "./Components/UploadInspectionPhotos.js";
import InspectionDetails from "./Components/Inspecciones/InspectionDetails.js";
import SearchInspection from "./Components/Inspecciones/SearchInspection.js";
import SearchUnidades from "./Components/AdminDashboard/SearchUnidades.js";
import UnidadDetails from "./Components/AdminDashboard/UnidadesDetails.js";
import NuevaInspeccion from "./Components/Inspecciones/NuevaInspeccion.js";
import MobileNavbar from "./MobileNavbar.js";
import SearchOficial from "./Components/AdminDashboard/Oficiales/SearchOficiales.js";
import NuevoCliente from "./Components/AdminDashboard/Clientes/NuevoCliente.js";
import HikvisionPlayer from "./Components/AdminDashboard/Analitica/HikvisionPlayer.js";
import InfoEnlaces from "./Components/Enlaces/InfoEnlaces.js";
import EnlaceInfo from "./Components/Enlaces/EnlaceInfo.js";
import NuevoEmpleado from "./Components/AdminDashboard/Empleados/NuevoEmpleado.js";
import TodasLasInspecciones from "./Components/Inspecciones/TodasLasInspecciones.js";
import DownloadInspections from "./Components/Inspecciones/DownloadInspections.js";
import Formulario from "./Components/Jobs/Formulario.js";
import NuevaCotizacion from "./Components/Cotizaciones/NuevaCotizacion.js";
import CotizacionList from "./Components/Cotizaciones/CotizacionList.js";
import VisualizarCotizacion from "./Components/Cotizaciones/VisualizarCotizacion.js";
import PlateRecognition from "./Components/AdminDashboard/Analitica/PlateRecognition.js";
import NuevaSolicitud from "./Components/Solicitud/NuevaSolicitud.jsx";
import Administracion from "./Components/AdminDashboard/Administracion.jsx";
import "./App.css";
import QRSticker from "./Components/Inventario/QRSticker.jsx";
import InventoryId from "./Components/Inventario/InventoryId.jsx";
import QRStickerTwoCodes from "./Components/Inventario/QRStickersTwoCodes.jsx";

const AppContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const ContentContainer = styled(Box)({
  flex: 1,
});

export default function App() {
  return (
    <Router>
      <UserAuthContextProvider>
        <CssBaseline />
        <AppContainer>
          <Navbar />
          <ContentContainer>
            <Routes>
              <Route
                path="/radiosconvencionales"
                element={<RadiosConvencionales />}
              />
              <Route
                path="/nuevoinventario"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevoInventario />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/administracion"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <Administracion />
                  </ProtectedRoute>
                }
              >
                <Route path="inventario" element={<Inventario />} />
                <Route path="NuevoEmpleado" element={<NuevoEmpleado />} />
                <Route path="inspecciones" element={<TodasLasInspecciones />} />
              </Route>
              <Route
                path="/buscarinspeccion"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <SearchInspection />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/empleados"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevoEmpleado />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/nuevoempleados"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevoEmpleado />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/NuevoCliente"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevoCliente />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/IventarioCompleto"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevoCliente />
                  </ProtectedRoute>
                }
              />
              <Route path="/algolia" element={<Algoliaimport />} />
              <Route path="/radios" element={<Radios />} />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/inventario"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <Inventario />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/hikvision"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <HikvisionPlayer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/importCSV"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <ImportCSV />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/buscarunidades"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <SearchUnidades />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/uploadpic"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <UploadInspectionPhotos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/inspeccion/:uid"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <InspectionDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/allinspections"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <TodasLasInspecciones />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/downloadinspections"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <DownloadInspections />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/unidad/:uid"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <UnidadDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/nuevainspeccion/:uid"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevaInspeccion />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/nuevainspeccion"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <NuevaInspeccion />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/buscaroficial"
                element={
                  <ProtectedRoute roles={["admin"]}>
                    <SearchOficial />
                  </ProtectedRoute>
                }
              />
              <Route path="/cotizacion" element={<CotizacionList />} />

              {/* Ruta para visualizar una cotización específica */}
              <Route
                path="/cotizacion/proyecto/:uid"
                element={<VisualizarCotizacion />}
              />
              <Route path="/nuevacotizacion" element={<NuevaCotizacion />} />
              <Route path="/formulario" element={<Formulario />} />
              <Route path="/enlaces" element={<InfoEnlaces />} />
              <Route path="/enlace/:uid" element={<EnlaceInfo />} />
              <Route path="/carrito" element={<Cart />} />
              <Route path="/search" element={<ProductList />} />
              <Route path="/contacto" element={<Contact />} />
              <Route path="/projectdashboard" element={<ProjectDashboard />} />
              <Route path="/addproject/:id" element={<AddProject />} />
              <Route path="/addproject" element={<AddProject />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/import" element={<ProductImport />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registro" element={<Signup />} />
              <Route path="/home" element={<UserDashboard />} />
              <Route path="/addstore" element={<AddStore />} />
              <Route path="/" element={<Home />} />
              <Route path="/myprojects" element={<MyProjects />} />
              <Route path="/plate" element={<PlateRecognition />} />
              <Route path="/solicitud" element={<NuevaSolicitud />} />
              <Route path="/stickers" element={<QRSticker />} />
              <Route path="/2stickers" element={<QRStickerTwoCodes />} />
              <Route path="/inventario/:uid" element={<InventoryId />} />

              <Route
                path="/producto/:documentName"
                element={<ProductDetails />}
              />
            </Routes>
          </ContentContainer>
          <Footer />
          <MobileNavbar />
        </AppContainer>
      </UserAuthContextProvider>
    </Router>
  );
}
