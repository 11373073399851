import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";

const BrandModelForm = () => {
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
  });
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const unsubscribeBrands = onSnapshot(
      collection(db, "brands"),
      (snapshot) => {
        setBrands(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    return () => {
      unsubscribeBrands();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddBrand = async () => {
    if (!formData.brand.trim()) {
      alert("El nombre de la marca no puede estar vacío.");
      return;
    }

    try {
      const brandDoc = doc(collection(db, "brands"));
      await setDoc(brandDoc, {
        name: formData.brand,
        createdAt: new Date(),
      });
      alert("Marca añadida correctamente!");
      setFormData((prev) => ({ ...prev, brand: "" }));
    } catch (error) {
      console.error("Error al añadir marca: ", error);
      alert("Ocurrió un error. Intenta de nuevo.");
    }
  };

  const handleAddModel = async () => {
    const selectedBrand = brands.find((brand) => brand.id === formData.brand);

    if (!selectedBrand) {
      alert("Debe seleccionar una marca válida para añadir un modelo.");
      return;
    }

    if (!formData.model.trim()) {
      alert("El nombre del modelo no puede estar vacío.");
      return;
    }

    try {
      const modelDoc = doc(
        collection(db, `brands/${selectedBrand.id}/models`)
      );
      await setDoc(modelDoc, {
        name: formData.model,
        createdAt: new Date(),
      });
      alert("Modelo añadido correctamente!");
      setFormData((prev) => ({ ...prev, model: "" }));
    } catch (error) {
      console.error("Error al añadir modelo: ", error);
      alert("Ocurrió un error. Intenta de nuevo.");
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-700">
        Añadir Marca y Modelo
      </h2>

      {/* Formulario para marcas */}
      <div className="mb-6">
        <input
          type="text"
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
          placeholder="Nombre de la marca"
          className="border p-3 rounded w-full mb-4"
        />
        <button
          onClick={handleAddBrand}
          className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
        >
          Guardar Marca
        </button>
      </div>

      {/* Formulario para modelos */}
      <div>
        <select
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
          className="border p-3 rounded w-full mb-4"
        >
          <option value="">Seleccionar Marca</option>
          {brands.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          name="model"
          value={formData.model}
          onChange={handleInputChange}
          placeholder="Nombre del modelo"
          className="border p-3 rounded w-full mb-4"
        />
        <button
          onClick={handleAddModel}
          className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
        >
          Guardar Modelo
        </button>
      </div>
    </div>
  );
};

export default BrandModelForm;
