import React, { useState, useRef, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { Barcode, X } from 'lucide-react';

const BarcodeScanner = ({ setNumInventario, setMessage }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [lastScanned, setLastScanned] = useState('');
  const [scanning, setScanning] = useState(false);
  const [duplicateAlert, setDuplicateAlert] = useState(false);
  const videoRef = useRef(null);
  const readerRef = useRef(null);
  const lastScanTimeRef = useRef(0);
  const scannedCodesRef = useRef(new Set());

  const styles = {
    scanLine: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      height: '2px',
      backgroundColor: '#ef4444',
      animation: 'scan 2s linear infinite',
    },
    videoContainer: {
      position: 'relative',
      width: '100%',
      aspectRatio: '16/9',
      backgroundColor: '#000',
      borderRadius: '0.5rem',
      overflow: 'hidden',
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  };

  useEffect(() => {
    readerRef.current = new BrowserMultiFormatReader(undefined, {
      delayBetweenScanAttempts: 1000, // Increased to 1 second
      delayBetweenScanSuccess: 1000
    });

    return () => {
      stopScanning();
    };
  }, []);

  const startScanning = async () => {
    try {
      setShowScanner(true);
      setScanning(true);
      setLastScanned('');
      scannedCodesRef.current.clear(); // Clear cached codes when starting new session

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' }
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      await readerRef.current.decodeFromConstraints(
        {
          video: {
            facingMode: 'environment',
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
          }
        },
        videoRef.current,
        (result, error) => {
          if (result) {
            const currentTime = Date.now();
            const timeSinceLastScan = currentTime - lastScanTimeRef.current;
            
            // Check if enough time has passed since last scan (1 second)
            if (timeSinceLastScan >= 1000) {
              const scannedText = result.getText();
              
              // Check if code was already scanned
              if (scannedCodesRef.current.has(scannedText)) {
                setDuplicateAlert(true);
                setTimeout(() => setDuplicateAlert(false), 3000);
                return;
              }

              handleSuccess(scannedText);
              lastScanTimeRef.current = currentTime;
              scannedCodesRef.current.add(scannedText);
            }
          }
          if (error && !error.message.includes('NotFoundException')) {
            console.error('Error de escaneo:', error);
          }
        }
      );
    } catch (err) {
      console.error('Error al iniciar la cámara:', err);
      setMessage('Error al acceder a la cámara. Verifica los permisos.');
      setScanning(false);
    }
  };

  const stopScanning = () => {
    setScanning(false);
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    if (readerRef.current) {
      readerRef.current.reset();
    }
    setShowScanner(false);
    setDuplicateAlert(false);
  };

  const handleSuccess = (decodedText) => {
    playBeepSound();
    setLastScanned(decodedText);
    setNumInventario(decodedText);
    setMessage(`Código escaneado: ${decodedText}`);
  };

  const playBeepSound = () => {
    const audio = new Audio(
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/sounds%2FBarcode%20scanner%20beep%20sound%20(sound%20effect)%20-%20sound%20effects%20(youtube).mp3?alt=media&token=eb15bedc-8447-4733-88b3-a0ba017846aa"
    );
    audio.play().catch(console.error);
  };

  const handleRescan = (code) => {
    scannedCodesRef.current.delete(code);
    setDuplicateAlert(false);
  };

  return (
    <div className="mb-4">
      <button
        onClick={startScanning}
        className="flex items-center px-4 py-2 text-sm font-medium text-white bg-purple-500 rounded-lg hover:bg-purple-600 transition duration-300"
      >
        <Barcode className="w-5 h-5 mr-2" />
        Escanear Código de Barras
      </button>

      {showScanner && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-4/5 max-w-md animate-fade-in relative">
            <div className="p-6">
              <button
                onClick={stopScanning}
                className="absolute top-4 right-4 text-red-500 hover:text-red-600 transition duration-300"
                aria-label="Cerrar escáner"
              >
                <X className="w-6 h-6" />
              </button>

              <h2 className="text-lg font-semibold text-gray-800 text-center mb-4">
                Escanear Código de Barras
              </h2>

              {duplicateAlert && (
                <div className="mb-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded">
                  <div className="flex items-center">
                    <div className="flex-1 text-yellow-700">
                      Este código ya fue escaneado.
                      <button
                        onClick={() => handleRescan(lastScanned)}
                        className="ml-2 text-blue-600 hover:underline focus:outline-none"
                      >
                        ¿Escanear de nuevo?
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div style={styles.videoContainer}>
                <video
                  ref={videoRef}
                  style={styles.video}
                  playsInline
                  autoPlay
                  muted
                />
                {scanning && <div style={styles.scanLine} />}
              </div>

              <div className="mt-4 text-center">
                <p className="text-sm text-gray-600">
                  Centra el código de barras en la cámara
                </p>
                {lastScanned && (
                  <div className="mt-2 p-2 bg-green-100 rounded-lg">
                    <p className="text-sm font-medium text-green-800">
                      Último código escaneado:
                    </p>
                    <p className="text-lg font-bold text-green-900">
                      {lastScanned}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          @keyframes scan {
            0% { transform: translateY(0); }
            100% { transform: translateY(100%); }
          }
        `}
      </style>
    </div>
  );
};

export default BarcodeScanner;