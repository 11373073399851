import React, { useState } from "react";
import { Trash } from "lucide-react";
import EscanerQR from "./EscanerQR";
import BarcodeScanner from "./BarcodeScanner";

const CodigosManager = ({ codigos, setCodigos, codigoAlta, setCodigoAlta, stock, setStock, setMessage }) => {
  const [manualCode, setManualCode] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);

  const handleAddCodigo = (codigo) => {
    if (!codigo) {
      alert("El código no puede estar vacío.");
      return;
    }

    const timestamp = new Date().toISOString();
    setCodigos((prev) => [...prev, codigo]);
    setCodigoAlta((prev) => [...prev, { codigo, fechaAlta: timestamp }]);
    setStock((prev) => prev + 1);
    setMessage(`Código ingresado: ${codigo}`);
  };

  const handleRemoveCodigo = (codigo) => {
    setCodigos((prev) => prev.filter((c) => c !== codigo));
    setCodigoAlta((prev) => prev.filter((item) => item.codigo !== codigo));
    setStock((prev) => prev - 1);
    setMessage(`Código eliminado: ${codigo}`);
  };

  const handleManualCodeSubmit = () => {
    if (manualCode.trim() === "") {
      setMessage("El código manual no puede estar vacío.");
      return;
    }
    handleAddCodigo(manualCode);
    setManualCode("");
  };

  return (
    <div>
      <div className="mt-4">
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Ingresa un Código Manualmente
        </label>
        <div className="flex items-center gap-2">
          <input
            type="text"
            value={manualCode}
            onChange={(e) => setManualCode(e.target.value)}
            placeholder="Ingresa el código"
            className="border p-3 rounded w-full"
          />
          <button
            onClick={handleManualCodeSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Agregar
          </button>
        </div>
      </div>

      <div className="mt-4">
        <EscanerQR
          showQrScanner={showQrScanner}
          setShowQrScanner={setShowQrScanner}
          setNumInventario={handleAddCodigo}
          setMessage={setMessage}
        />
        <BarcodeScanner
          setNumInventario={handleAddCodigo}
          setMessage={setMessage}
        />
      </div>

      {codigos.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-medium text-gray-700 mb-2">Códigos:</h3>
          <ul className="space-y-2">
            {codigos.map((codigo, index) => (
              <li
                key={index}
                className="flex items-center justify-between bg-gray-100 p-2 rounded-lg"
              >
                <span className="text-gray-600">{codigo}</span>
                <button
                  onClick={() => handleRemoveCodigo(codigo)}
                  className="text-red-500 hover:text-red-600"
                >
                  <Trash className="w-5 h-5" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CodigosManager;
