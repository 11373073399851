import React, { useState } from "react";
import InventoryForm from "./InventoryForm";
import CategoryForm from "./CategoryForm";
import BrandModelForm from "./BrandModelForm";

const InventoryManager = () => {
  const [tab, setTab] = useState("inventory"); // Controla la pestaña activa

  return (
    <div className="p-4 max-w-6xl mx-auto bg-gray-100 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-700">
        Gestión de Inventario
      </h1>

      <div className="mb-6 flex gap-4">
        <button
          className={`px-6 py-2 rounded-lg ${
            tab === "inventory"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => setTab("inventory")}
        >
          Inventario
        </button>
        <button
          className={`px-6 py-2 rounded-lg ${
            tab === "category"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => setTab("category")}
        >
          Categorías
        </button>
        <button
          className={`px-6 py-2 rounded-lg ${
            tab === "brand"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => setTab("brand")}
        >
          Marcas y Modelos
        </button>
      </div>

      {tab === "inventory" && <InventoryForm />}
      {tab === "category" && <CategoryForm />}
      {tab === "brand" && <BrandModelForm />}
    </div>
  );
};

export default InventoryManager;
