import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Inventory2,
  ContactMail,
  AssignmentTurnedIn,
  Build,
  LocalPolice,
} from "@mui/icons-material";
import AdminMenu from "./AdminMenu";

const AdminDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const menuItems = [
    {
      text: "Unidades",
      icon: <LocalPolice />,
      path: "/buscarunidades",
    },
    {
      text: "Administración",
      icon: <LocalPolice />,
      path: "/administracion",
    },
    {
      text: "Analítica",
      icon: <LocalPolice />,
      path: "/hikvision",
    },
    {
      text: "Nuevo Inventario",
      icon: <Inventory2 />,
      path: "/nuevoinventario",
    },
    {
      text: "Inspecciones",
      icon: <AssignmentTurnedIn />,
      path: "/buscarinspeccion",
    },
    {
      text: "Nueva Inspección",
      icon: <AssignmentTurnedIn />,
      path: "/nuevainspeccion",
    },
    {
      text: "Buscar Oficial",
      icon: <AssignmentTurnedIn />,
      path: "/buscaroficial",
    },
    {
      text: "Reconocimiento de Placas",
      icon: <AssignmentTurnedIn />,
      path: "/plate",
    },
    { text: "Contactos", icon: <ContactMail />, path: "/contactos" },
    { text: "Garantías", icon: <Build />, path: "/garantias" },
  ];

  return (
    <Box
      sx={{
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
        p: 3,
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          sx={{
            my: 4,
            color: "#00ffff",
            borderBottom: "3px solid #00ffff",
            paddingBottom: "12px",
            fontWeight: "bold",
          }}
        >
          Accesos Directos
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {menuItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Button
                component={Link}
                to={item.path}
                variant="contained"
                startIcon={item.icon}
                fullWidth
                sx={{
                  py: isMobile ? 1.2 : 1.5,
                  px: 2,
                  fontSize: isMobile ? "0.85rem" : "1rem",
                  textTransform: "none",
                  borderRadius: 2,
                  boxShadow: "0 4px 10px rgba(0, 255, 255, 0.3)",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 10px 20px rgba(0, 255, 255, 0.5)",
                  },
                  transition: "all 0.3s ease-in-out",
                  backgroundColor: "#00C49F",
                  color: "#000000",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  minHeight: "56px",
                  gap: 1.5,
                }}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 6 }}>
          <AdminMenu />
        </Box>
      </Container>
    </Box>
  );
};

export default AdminDashboard;
