import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { Camera } from "lucide-react";
import CodigosManager from "./NuevoInventario/CodigosManager";

const CACHE_KEY = "inventoryFormCache";

const InventoryForm = () => {
  const [formData, setFormData] = useState(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    return cachedData
      ? JSON.parse(cachedData)
      : {
          name: "",
          description: "",
          category: "",
          subcategory: "",
          brand: "",
          model: "",
          stock: 0,
          minStock: 0,
          price: 0,
          images: [],
        };
  });

  const [codigos, setCodigos] = useState([]);
  const [codigoAlta, setCodigoAlta] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    localStorage.setItem(CACHE_KEY, JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const unsubscribeCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        setCategories(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );

    const unsubscribeBrands = onSnapshot(
      collection(db, "brands"),
      (snapshot) => {
        setBrands(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    return () => {
      unsubscribeCategories();
      unsubscribeBrands();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "brand") {
      const selectedBrand = brands.find((brand) => brand.id === value);
      if (selectedBrand) {
        onSnapshot(
          collection(db, `brands/${selectedBrand.id}/models`),
          (snapshot) => {
            setModels(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          }
        );
      } else {
        setModels([]);
      }
    }

    if (name === "category") {
      const selectedCategory = categories.find(
        (category) => category.id === value
      );
      if (selectedCategory) {
        onSnapshot(
          collection(db, `categories/${selectedCategory.id}/subcategories`),
          (snapshot) => {
            setSubcategories(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          }
        );
      } else {
        setSubcategories([]);
      }
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...formData.images];

    files.forEach((file) => {
      const storageRef = ref(storage, `inventory/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error al subir la imagen:", error);
        },
        async () => {
          const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
          updatedImages.push({ url: imageURL, name: file.name });
          setFormData((prev) => ({ ...prev, images: updatedImages }));
        }
      );
    });
  };

  const handleRemoveImage = (image) => {
    const storageRef = ref(storage, `inventory/${image.name}`);
    deleteObject(storageRef)
      .then(() => {
        setFormData((prev) => ({
          ...prev,
          images: prev.images.filter((img) => img.url !== image.url),
        }));
      })
      .catch((error) => {
        console.error("Error al eliminar la imagen:", error);
      });
  };

  const handleSubmit = async () => {
    try {
      const inventoryDoc = doc(collection(db, "inventory"));
      await setDoc(inventoryDoc, {
        ...formData,
        codigos,
        codigoAlta,
        stock: Number(formData.stock),
        minStock: Number(formData.minStock),
        price: Number(formData.price),
        createdAt: new Date(),
      });
      alert("Producto añadido al inventario correctamente!");
      clearForm();
    } catch (error) {
      console.error("Error al añadir al inventario: ", error);
      alert("Ocurrió un error. Intenta de nuevo.");
    }
  };

  const clearForm = () => {
    localStorage.removeItem(CACHE_KEY);
    setFormData({
      name: "",
      description: "",
      category: "",
      subcategory: "",
      brand: "",
      model: "",
      stock: 0,
      minStock: 0,
      price: 0,
      images: [],
    });
    setCodigos([]);
    setCodigoAlta([]);
    setMessage("");
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-700">
        Añadir al Inventario
      </h2>
      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Nombre del producto"
          className="border p-3 rounded w-full"
        />
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Descripción"
          className="border p-3 rounded w-full"
        />
        <select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          className="border p-3 rounded w-full"
        >
          <option value="">Seleccionar Categoría</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
        <select
          name="subcategory"
          value={formData.subcategory}
          onChange={handleInputChange}
          className="border p-3 rounded w-full"
        >
          <option value="">Seleccionar Subcategoría</option>
          {subcategories.map((sub) => (
            <option key={sub.id} value={sub.id}>
              {sub.name}
            </option>
          ))}
        </select>
        <select
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
          className="border p-3 rounded w-full"
        >
          <option value="">Seleccionar Marca</option>
          {brands.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </select>
        <select
          name="model"
          value={formData.model}
          onChange={handleInputChange}
          className="border p-3 rounded w-full"
        >
          <option value="">Seleccionar Modelo</option>
          {models.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name}
            </option>
          ))}
        </select>
        <input
          type="number"
          name="stock"
          value={formData.stock}
          onChange={handleInputChange}
          placeholder="Stock"
          className="border p-3 rounded w-full"
          readOnly
        />
        <input
          type="number"
          name="minStock"
          value={formData.minStock}
          onChange={handleInputChange}
          placeholder="Stock mínimo"
          className="border p-3 rounded w-full"
        />
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          placeholder="Precio"
          className="border p-3 rounded w-full"
        />
        <div className="col-span-2">
          <label className="flex flex-col items-center gap-2 cursor-pointer border-dashed border-2 border-gray-400 p-4 rounded-lg">
            <Camera className="text-gray-500 w-8 h-8" />
            <span className="text-sm text-gray-500">Subir imágenes</span>
            <input
              type="file"
              onChange={handleFileChange}
              multiple
              className="hidden"
            />
          </label>
        </div>
      </div>

      <CodigosManager
        codigos={codigos}
        setCodigos={setCodigos}
        codigoAlta={codigoAlta}
        setCodigoAlta={setCodigoAlta}
        stock={formData.stock}
        setStock={(stock) => setFormData((prev) => ({ ...prev, stock }))}
        setMessage={setMessage}
      />

      <div className="flex gap-4 mt-4">
        <button
          onClick={handleSubmit}
          className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700"
        >
          Guardar Producto
        </button>
        <button
          onClick={clearForm}
          className="px-6 py-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Limpiar Formulario
        </button>
      </div>
    </div>
  );
};

export default InventoryForm;
