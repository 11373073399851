import React, { useState } from "react";
import QRCode from "qrcode.react";
import { jsPDF } from "jspdf";
import base64Logo from "./base64Logo";

const QRStickerTwoCodes = () => {
  const [codes, setCodes] = useState([]);

  const generateRandomCode = () => {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array.from({ length: 10 }, () =>
      chars.charAt(Math.floor(Math.random() * chars.length))
    ).join("");
  };

  const generateCodes = () => {
    const newCodes = Array.from({ length: 20 }, () => generateRandomCode());
    setCodes(newCodes);
  };

  const getQRCodeDataURL = (code) => {
    const canvas = document.createElement("canvas");
    QRCode.toCanvas(canvas, code, {
      width: 60,
      height: 60,
    });
    return canvas.toDataURL("image/png");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      unit: "mm",
      format: [215.9, 279.4],
    });

    const positions = [];
    const startX = 15;
    const startY = 15;
    const boxWidth = 90;
    const boxHeight = 50;
    const colGap = 5;
    const rowGap = 5;

    for (let row = 0; row < 5; row++) {
      for (let col = 0; col < 2; col++) {
        positions.push({
          x: startX + col * (boxWidth + colGap),
          y: startY + row * (boxHeight + rowGap),
        });
      }
    }

    try {
      for (let i = 0; i < positions.length; i++) {
        const { x, y } = positions[i];
        const baseIndex = i * 2;

        // Horizontal layout for 2 QR codes within each box
        const qrPositions = [
          { dx: 10, dy: 10 }, // Left center
          { dx: 50, dy: 10 }, // Right center
        ];

        // Add 2 QR codes in each box
        for (let j = 0; j < 2; j++) {
          const codeIndex = baseIndex + j;
          if (codeIndex < codes.length) {
            const { dx, dy } = qrPositions[j];
            doc.addImage(base64Logo, "PNG", x + dx - 5, y + dy - 10, 25, 12);
            const qrDataURL = getQRCodeDataURL(codes[codeIndex]);
            doc.addImage(qrDataURL, "PNG", x + dx + 20, y + dy - 5, 25, 25);
            doc.setFontSize(10);
            doc.text(codes[codeIndex], x + dx + 20, y + dy + 30, { maxWidth: 40 });
          }
        }
      }

      doc.save("QR_Stickers_TwoCodes.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("There was a problem generating the PDF. Please verify the data is valid.");
    }
  };

  const printStyles = `
    @media print {
      body * {
        visibility: hidden;
      }
      #printable-content,
      #printable-content * {
        visibility: visible;
      }
      #printable-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 215.9mm;
        height: 279.4mm;
        padding: 5mm 15mm;
      }
      .print-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5mm;
        width: 100%;
        height: 100%;
      }
      .print-box {
        border: 1px solid #ccc;
        height: 50mm;
        padding: 2mm;
      }
      @page {
        size: letter;
        margin: 0;
      }
    }
  `;

  return (
    <div className="p-4">
      <style>{printStyles}</style>

      <div className="flex gap-4 mb-6 print:hidden">
        <button
          onClick={generateCodes}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Generar Códigos
        </button>
        <button
          onClick={downloadPDF}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Descargar PDF
        </button>
        <button
          onClick={() => window.print()}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Imprimir Página
        </button>
      </div>

      <div id="printable-content">
        <div className="print-grid">
          {Array.from({ length: 10 }).map((_, boxIndex) => (
            <div
              key={boxIndex}
              className="print-box flex flex-row justify-around items-center"
            >
              {[0, 1].map((stickerIndex) => {
                const code = codes[boxIndex * 2 + stickerIndex];
                return code ? (
                  <div key={stickerIndex} className="flex flex-col items-center justify-center gap-1">
                    <img
                      src={base64Logo}
                      alt="Logo"
                      className="h-8 w-16 object-contain"
                    />
                    <QRCode value={code} size={60} />
                    <div className="text-[10px]">{code}</div>
                  </div>
                ) : null;
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QRStickerTwoCodes;
