import React, { useState } from "react";
import QRCode from "qrcode.react";
import { jsPDF } from "jspdf";
import base64Logo from "./base64Logo";

const QRSticker = () => {
  const [codes, setCodes] = useState([]);

  const generateRandomCode = () => {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array.from({ length: 10 }, () =>
      chars.charAt(Math.floor(Math.random() * chars.length))
    ).join("");
  };

  const generateCodes = () => {
    const newCodes = Array.from({ length: 60 }, () => generateRandomCode());
    setCodes(newCodes);
  };

  const getQRCodeDataURL = (code) => {
    const canvas = document.createElement("canvas");
    QRCode.toCanvas(canvas, code, {
      width: 40,
      height: 40,
    });
    return canvas.toDataURL("image/png");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      unit: "mm",
      format: [215.9, 279.4], // Tamaño carta
    });

    const startX = 4; // Padding lateral izquierdo de 4 mm
    const startY = 14; // Cambiado: Padding superior ajustado a 14 mm (+2 mm sobre el padding original)
    const boxWidth = 66.675; // Ancho de cada recuadro (2 5/8 pulgadas en mm)
    const boxHeight = 25.4; // Altura de cada recuadro (1 pulgada en mm)
    const colGap = 5; // Espacio horizontal entre columnas
    const extraRightPadding = 2; // Padding adicional del lado derecho

    let index = 0;

    for (let row = 0; row < 10; row++) {
      for (let col = 0; col < 3; col++) {
        if (index >= codes.length) break;

        const x = startX + col * (boxWidth + colGap);
        const y = startY + row * boxHeight;

        // Cambiado: Posiciones horizontales de los dos códigos QR dentro del recuadro para acercarlos más
        const qrPositions = [
          { offsetX: 18 }, // Primer código QR más centrado
          { offsetX: 38 }, // Segundo código QR más cerca del primero
        ];

        qrPositions.forEach((pos) => {
          if (index < codes.length) {
            const qrCode = codes[index];
            const offsetX = x + pos.offsetX;

            // Añadir logo encima del QR
            doc.addImage(base64Logo, "PNG", offsetX, y + 5, 15, 7);

            // Añadir QR Code
            const qrDataURL = getQRCodeDataURL(qrCode);
            doc.addImage(qrDataURL, "PNG", offsetX, y + 13, 15, 15);

            // Añadir texto debajo del QR Code
            doc.setFontSize(8);
            doc.text(qrCode, offsetX, y + 30, { maxWidth: 20 });

            index++;
          }
        });

        // Dibujar líneas del recuadro (casi transparentes)
        doc.setDrawColor(220); // Color gris claro
        doc.rect(x, y, boxWidth, boxHeight);
      }
    }

    // Ajustar el ancho total para incluir el padding derecho extra
    doc.setPage(1);
    doc.save("QR_Stickers_60.pdf");
  };

  const printStyles = `
    @media print {
      body * {
        visibility: hidden;
      }
      #printable-content,
      #printable-content * {
        visibility: visible;
      }
      #printable-content {
        position: absolute;
        left: 0;
        top: 0;
        width: calc(215.9mm + 2mm); /* Ajustar ancho con padding derecho extra */
        height: 279.4mm;
        padding: 14mm 6mm 12mm 4mm; /* Cambiado: Padding superior ajustado a 14mm, derecho 6mm, inferior 12mm, izquierdo 4mm */
      }
      .print-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 0;
        column-gap: 5mm; /* Mantener margen horizontal */
      }
      .print-box {
  border: 1px solid rgba(200, 200, 200, 0.0); /* Líneas casi transparentes */
  height: 25.4mm; /* Altura ajustada a 1 pulgada */
  width: 66.675mm; /* Ancho ajustado a 2 5/8 pulgadas */
  display: flex;
  justify-content: center; /* Centrar los elementos horizontalmente */
  align-items: center; /* Centrar los elementos verticalmente */
  padding: 0; /* Eliminado el padding adicional */
  gap: 16mm; /* Espaciado balanceado entre los QR */
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2mm; /* Espacio entre logo, QR y texto */
}

@page {
  size: letter;
  margin: 0;
}
    }
  `;

  return (
    <div className="p-4">
      <style>{printStyles}</style>

      <div className="flex gap-4 mb-6 print:hidden">
        <button
          onClick={generateCodes}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Generar Códigos
        </button>
        <button
          onClick={downloadPDF}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Descargar PDF
        </button>
        <button
          onClick={() => window.print()}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Imprimir Página
        </button>
      </div>

      <div id="printable-content">
        <div className="print-grid">
          {Array.from({ length: 30 }).map((_, recIndex) => (
            <div key={recIndex} className="print-box">
              {[0, 1].map((subIndex) => {
                const codeIndex = recIndex * 2 + subIndex;
                const code = codes[codeIndex];
                return code ? (
                  <div key={subIndex} className="qr-container">
                    <img
                      src={base64Logo}
                      alt="Logo"
                      className="h-4 w-8 object-contain"
                    />
                    <QRCode value={code} size={40} />
                    <div className="text-[8px]">{code}</div>
                  </div>
                ) : null;
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QRSticker;
