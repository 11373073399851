import React from "react";
import { Radio, Shield, Wrench, Clock } from "lucide-react";

const features = [
  {
    title: "Puntos de Repetición",
    description:
      "Contamos con puntos de repetición de radio frecuencias estratégicamente ubicados en Hermosillo, Sonora.",
    icon: Radio,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/122094597_182670086833241_1973500611783783661_n-1500w.jpg?alt=media&token=9d945a7a-ccb3-4beb-8430-5108ccfd9f2d",
    details:
      "Ofrecemos espacios disponibles para el alquiler de repetidoras, permitiendo una conexión confiable y de calidad para sus comunicaciones.",
  },
  {
    title: "Equipamiento",
    description:
      "Nuestro equipo comprende la importancia de la seguridad pública y está comprometido con brindar soluciones confiables.",
    icon: Shield,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/frente%20nice-1500w.png?alt=media&token=c23d47fe-56ee-4d79-acac-3c3788079e82",
    details:
      "Instalamos equipos de seguridad de manera profesional, siguiendo las mejores prácticas de la industria.",
  },
  {
    title: "Tecnologías de Vanguardia",
    description:
      "Nuestros drones están equipados con las últimas características y capacidades para operaciones de seguridad.",
    icon: Wrench,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/img_20230613_1423511-1500w.jpg?alt=media&token=59d631dd-3db5-42db-abc5-94659d551fb5",
    details:
      "Utilizamos drones para vigilancia, monitoreo aéreo, y tareas de búsqueda y rescate, ofreciendo herramientas efectivas y avanzadas.",
  },
  {
    title: "Experiencia",
    description:
      "Más de 30 años de experiencia en el campo de la seguridad, ofreciendo soluciones tanto para clientes públicos como privados.",
    icon: Clock,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/aereas-_entrega_de_patrullas_03-1500w.jpg?alt=media&token=a4b133aa-48f3-4e57-9d71-24b1510a0927",
    details:
      "Con tres décadas de experiencia, garantizamos la implementación y mantenimiento de soluciones de seguridad confiables.",
  },
];

const FeatureCard = ({ feature }) => {
  const Icon = feature.icon;

  return (
    <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
      <div className="bg-white rounded-2xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105 h-full">
        <img
          src={feature.media}
          alt={feature.title}
          className="w-full h-64 object-cover"
        />
        <div className="p-6">
          <div className="w-16 h-16 mx-auto mb-4 bg-white rounded-full flex items-center justify-center shadow-md">
            <Icon className="w-8 h-8 text-blue-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-3 text-center">
            {feature.title}
          </h3>
          <p className="text-gray-600 mb-4 leading-relaxed">
            {feature.description}
          </p>
          <p className="text-blue-600 leading-relaxed">{feature.details}</p>
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <section
      id="features"
      className="py-16 px-4 bg-gradient-to-br from-white to-gray-100"
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-wrap -mx-4">
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
