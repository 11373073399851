import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const center = {
  lat: 29.129467,
  lng: -110.937223,
};

const CoverageMap = () => {
  const [map, setMap] = useState(null);
  const [circles, setCircles] = useState([]);
  const [markerIcon, setMarkerIcon] = useState(null);
  const [selectedRadius, setSelectedRadius] = useState(null);

  const coverageAreas = [
    { radius: 15000, color: "#22c55e", opacity: 0.3, label: "Señal Excelente" },
    {
      radius: 25000,
      color: "#84cc16",
      opacity: 0.25,
      label: "Señal Muy Buena",
    },
    { radius: 35000, color: "#eab308", opacity: 0.2, label: "Señal Buena" },
    { radius: 45000, color: "#f97316", opacity: 0.15, label: "Señal Regular" },
    { radius: 50000, color: "#ef4444", opacity: 0.1, label: "Señal Débil" },
  ];

  useEffect(() => {
    if (window.google) {
      setMarkerIcon({
        url: "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/RMC264636EFFF4_0.png?alt=media&token=8ae42d00-bbd8-43c2-825d-945d8c8f333e",
        scaledSize: new window.google.maps.Size(64, 64),
        anchor: new window.google.maps.Point(32, 32),
      });
    }
  }, []);

  useEffect(() => {
    if (map) {
      circles.forEach((circle) => circle.setMap(null));

      const newCircles = coverageAreas.map(({ radius, color, opacity }) => {
        const circle = new window.google.maps.Circle({
          map,
          center,
          radius,
          fillColor: color,
          fillOpacity: selectedRadius === radius ? opacity * 1.5 : opacity,
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: selectedRadius === radius ? 2 : 1,
          clickable: true,
          zIndex: selectedRadius === radius ? 2 : 1,
        });

        circle.addListener("click", () => {
          setSelectedRadius(radius === selectedRadius ? null : radius);
          const zoom =
            radius <= 15000
              ? 12
              : radius <= 25000
              ? 11
              : radius <= 35000
              ? 10
              : radius <= 45000
              ? 9
              : 8;
          map.setZoom(zoom);
          map.setCenter(center);
        });

        return circle;
      });

      setCircles(newCircles);
      return () => newCircles.forEach((circle) => circle.setMap(null));
    }
  }, [map, selectedRadius]);

  const onMapLoad = (loadedMap) => {
    setMap(loadedMap);
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-4">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">
          Área de Cobertura
        </h2>
        <p className="text-gray-600">Haga clic en un área para ver detalles</p>
      </div>

      <div className="relative w-full h-[600px] rounded-2xl overflow-hidden shadow-xl">
        <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            center={center}
            zoom={10}
            onLoad={onMapLoad}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: true,
              styles: [
                {
                  featureType: "all",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#6b7280" }],
                },
              ],
            }}
          >
            {markerIcon && (
              <Marker position={center} icon={markerIcon} zIndex={1000} />
            )}
          </GoogleMap>
        </LoadScript>
      </div>

      <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4">
        {coverageAreas.map((area) => (
          <button
            key={area.radius}
            onClick={() =>
              setSelectedRadius(
                area.radius === selectedRadius ? null : area.radius
              )
            }
            className={`p-4 rounded-lg text-sm font-medium transition-all duration-300 ${
              selectedRadius === area.radius
                ? "bg-gray-800 text-white shadow-lg transform scale-105"
                : "bg-white text-gray-700 hover:bg-gray-50 shadow"
            }`}
            style={{ borderLeft: `4px solid ${area.color}` }}
          >
            <div className="text-left">
              <div className="font-bold mb-1">{area.label}</div>
              <div className="text-xs opacity-75">{area.radius / 1000} km</div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CoverageMap;
