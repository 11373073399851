import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import {
  ShoppingBag,
  Tag,
  Layers,
  Box,
  ImageIcon,
  Search,
  Edit3,
  Filter,
  Scan,
} from "lucide-react";
import InventoryPopup from "./InventoryPopup";
import { useNavigate } from "react-router-dom";

const InventoryViewer = () => {
  const [inventory, setInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    category: "",
    subcategory: "",
    brand: "",
    model: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [message, setMessage] = useState("");
  const [smartScannerActive, setSmartScannerActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeInventory = onSnapshot(
      collection(db, "inventory"),
      (snapshot) => {
        setInventory(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );

    const unsubscribeCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        setCategories(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );

    const unsubscribeBrands = onSnapshot(
      collection(db, "brands"),
      (snapshot) => {
        setBrands(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      }
    );

    return () => {
      unsubscribeInventory();
      unsubscribeCategories();
      unsubscribeBrands();
    };
  }, []);

  useEffect(() => {
    if (filters.category) {
      const selectedCategory = categories.find(
        (cat) => cat.id === filters.category
      );
      if (selectedCategory) {
        onSnapshot(
          collection(db, `categories/${selectedCategory.id}/subcategories`),
          (snapshot) => {
            setSubcategories(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          }
        );
      }
    } else {
      setSubcategories([]);
    }
  }, [filters.category, categories]);

  useEffect(() => {
    if (filters.brand) {
      const selectedBrand = brands.find((brand) => brand.id === filters.brand);
      if (selectedBrand) {
        onSnapshot(
          collection(db, `brands/${selectedBrand.id}/models`),
          (snapshot) => {
            setModels(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          }
        );
      }
    } else {
      setModels([]);
    }
  }, [filters.brand, brands]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
  };

  const handleCardClick = (id) => {
    navigate(`/inventario/${id}`);
  };

  const filteredInventory = inventory.filter((item) => {
    const matchesSearch =
      item.name.toLowerCase().includes(search) ||
      item.brand?.toLowerCase().includes(search) ||
      item.model?.toLowerCase().includes(search);
    return (
      matchesSearch &&
      (!filters.category || item.category === filters.category) &&
      (!filters.subcategory || item.subcategory === filters.subcategory) &&
      (!filters.brand || item.brand === filters.brand) &&
      (!filters.model || item.model === filters.model)
    );
  });

  const toggleSmartScanner = () => {
    setSmartScannerActive((prev) => !prev);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-4">
      <div className="w-full h-full bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="p-6">
          <h1 className="text-3xl font-bold text-center bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-6">
            Visualizar Inventario
          </h1>

          <button
            onClick={toggleSmartScanner}
            className="w-full flex items-center justify-center py-4 bg-green-500 text-white text-lg rounded-lg hover:bg-green-600 transition-all duration-200 mb-6"
          >
            <Scan className="w-6 h-6 mr-2" /> Smart Scanner
          </button>

          <div className="bg-gray-50 rounded-xl p-4 mb-6 shadow-inner">
            <div className="flex items-center gap-2 mb-4">
              <Filter className="text-blue-600" size={20} />
              <h2 className="text-lg font-semibold text-gray-800">
                Filtros de Búsqueda
              </h2>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
              <select
                name="category"
                value={filters.category}
                onChange={handleFilterChange}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 bg-white shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-blue-300"
              >
                <option value="">Todas las Categorías</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>

              <select
                name="subcategory"
                value={filters.subcategory}
                onChange={handleFilterChange}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 bg-white shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-blue-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
                disabled={!filters.category}
              >
                <option value="">Todas las Subcategorías</option>
                {subcategories.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                ))}
              </select>

              <select
                name="brand"
                value={filters.brand}
                onChange={handleFilterChange}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 bg-white shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-blue-300"
              >
                <option value="">Todas las Marcas</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </select>

              <select
                name="model"
                value={filters.model}
                onChange={handleFilterChange}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 bg-white shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-blue-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
                disabled={!filters.brand}
              >
                <option value="">Todos los Modelos</option>
                {models.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="relative">
              <input
                type="text"
                placeholder="Buscar por título, marca, modelo..."
                className="w-full px-3 py-2 pl-10 rounded-lg border border-gray-200 bg-white shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-blue-300"
                onChange={handleSearchChange}
              />
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={18}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {filteredInventory.length > 0 ? (
              filteredInventory.map((item) => (
                <div
                  key={item.id}
                  className="group bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer border border-gray-100"
                  onClick={() => handleCardClick(item.id)}
                >
                  <div className="aspect-w-1 aspect-h-1 bg-gray-50">
                    {item.images && item.images.length > 0 ? (
                      <img
                        src={item.images[0].url}
                        alt="Imagen principal"
                        className="w-full h-32 object-cover transform group-hover:scale-105 transition-transform duration-300"
                      />
                    ) : (
                      <div className="w-full h-32 flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100">
                        <ImageIcon size={32} className="text-gray-300" />
                      </div>
                    )}
                  </div>

                  <div className="p-4">
                    <h3 className="text-sm font-bold text-gray-800 mb-2 group-hover:text-blue-600 transition-colors">
                      {item.name}
                    </h3>

                    <div className="space-y-1">
                      <p className="flex items-center text-gray-600 text-xs">
                        <Tag className="w-4 h-4 mr-2 text-blue-500" />
                        {categories.find((cat) => cat.id === item.category)
                          ?.name || "Sin Categoría"}
                      </p>
                      <p className="flex items-center text-gray-600 text-xs">
                        <Layers className="w-4 h-4 mr-2 text-blue-500" />
                        {subcategories.find(
                          (sub) => sub.id === item.subcategory
                        )?.name || "Sin Subcategoría"}
                      </p>
                      <p className="flex items-center text-gray-600 text-xs">
                        <ShoppingBag className="w-4 h-4 mr-2 text-blue-500" />
                        {brands.find((brand) => brand.id === item.brand)
                          ?.name || "Sin Marca"}
                      </p>
                      <p className="flex items-center text-gray-600 text-xs">
                        <Box className="w-4 h-4 mr-2 text-blue-500" />
                        {models.find((model) => model.id === item.model)
                          ?.name || "Sin Modelo"}
                      </p>
                    </div>

                    <div className="mt-4 flex justify-between items-center text-sm">
                      <div>
                        Stock:{" "}
                        <span className="text-blue-600 font-bold">
                          {item.codigoAlta?.length || 0}
                        </span>
                      </div>
                      <div className="text-green-600 font-bold">
                        ${item.price.toFixed(2)}
                      </div>
                    </div>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(item);
                      }}
                      className="mt-2 w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 text-sm flex items-center justify-center gap-2 font-medium"
                    >
                      <Edit3 className="w-4 h-4" />
                      Editar
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-500">
                <Box className="w-12 h-12 mb-3 text-gray-300" />
                <p className="text-lg font-medium">
                  No se encontraron productos.
                </p>
                <p className="text-gray-400 mt-1">
                  Intenta con otros filtros de búsqueda
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {showPopup && (
        <InventoryPopup
          selectedItem={selectedItem}
          setShowPopup={setShowPopup}
          setMessage={setMessage}
          smartScannerActive={smartScannerActive}
        />
      )}
    </div>
  );
};

export default InventoryViewer;
