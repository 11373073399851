import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";

const CategoryForm = () => {
  const [formData, setFormData] = useState({
    category: "",
    subcategory: "",
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsubscribeCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        setCategories(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );

    return () => {
      unsubscribeCategories();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddCategory = async () => {
    if (!formData.category.trim()) {
      alert("El nombre de la categoría no puede estar vacío.");
      return;
    }

    try {
      const categoryDoc = doc(collection(db, "categories"));
      await setDoc(categoryDoc, {
        name: formData.category,
        createdAt: new Date(),
      });
      alert("Categoría añadida correctamente!");
      setFormData((prev) => ({ ...prev, category: "" }));
    } catch (error) {
      console.error("Error al añadir categoría: ", error);
      alert("Ocurrió un error. Intenta de nuevo.");
    }
  };

  const handleAddSubcategory = async () => {
    const selectedCategory = categories.find(
      (cat) => cat.id === formData.category
    );

    if (!selectedCategory) {
      alert("Debe seleccionar una categoría válida para añadir una subcategoría.");
      return;
    }

    if (!formData.subcategory.trim()) {
      alert("El nombre de la subcategoría no puede estar vacío.");
      return;
    }

    try {
      const subcategoryDoc = doc(
        collection(db, `categories/${selectedCategory.id}/subcategories`)
      );
      await setDoc(subcategoryDoc, {
        name: formData.subcategory,
        createdAt: new Date(),
      });
      alert("Subcategoría añadida correctamente!");
      setFormData((prev) => ({ ...prev, subcategory: "" }));
    } catch (error) {
      console.error("Error al añadir subcategoría: ", error);
      alert("Ocurrió un error. Intenta de nuevo.");
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-700">
        Añadir Categoría y Subcategoría
      </h2>

      {/* Formulario para categorías */}
      <div className="mb-6">
        <input
          type="text"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          placeholder="Nombre de la categoría"
          className="border p-3 rounded w-full mb-4"
        />
        <button
          onClick={handleAddCategory}
          className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Guardar Categoría
        </button>
      </div>

      {/* Formulario para subcategorías */}
      <div>
        <select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          className="border p-3 rounded w-full mb-4"
        >
          <option value="">Seleccionar Categoría</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          name="subcategory"
          value={formData.subcategory}
          onChange={handleInputChange}
          placeholder="Nombre de la subcategoría"
          className="border p-3 rounded w-full mb-4"
        />
        <button
          onClick={handleAddSubcategory}
          className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Guardar Subcategoría
        </button>
      </div>
    </div>
  );
};

export default CategoryForm;
