import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsIcon from "@mui/icons-material/Settings";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useUserAuth } from "./context/userAuthContext"; // Import authentication context

export default function MobileNavbar() {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the user has the "admin" role
  const isAdmin = user?.roles?.includes("admin");

  const routes = [
    { path: "/home", label: "Inicio", icon: HomeIcon },
    { path: "/search", label: "Buscar", icon: SearchIcon },
    { path: "/admin", label: "Admin", icon: AdminPanelSettingsIcon },
    { path: "/nuevainspeccion", label: "Nueva Inspección", icon: AddBoxIcon },
    { path: "/buscarunidades", label: "Unidades", icon: SettingsIcon },
    { path: "/inventario", label: "Inventario", icon: InventoryIcon },
  ];

  const getCurrentValue = () => {
    return routes.findIndex((route) => route.path === location.pathname);
  };

  // If the user is not an admin, do not render the MobileNavbar
  if (!isAdmin) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: "#ffffff", // White background for a clean look
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for separation
        borderRadius: "16px 16px 0 0", // Rounded top corners
        overflow: "hidden",
      }}
    >
      <BottomNavigation
        value={getCurrentValue()}
        onChange={(_, newValue) => navigate(routes[newValue].path)}
        sx={{
          height: 70,
          backgroundColor: "#ffffff", // White background for BottomNavigation
          "& .MuiBottomNavigationAction-root": {
            color: "rgba(0, 0, 0, 0.6)", // Icon color when not selected
            minWidth: "auto",
            padding: "6px 0",
            "&.Mui-selected": {
              color: "#1976d2", // Selected icon color
            },
          },
        }}
      >
        {routes.map((route, index) => (
          <BottomNavigationAction
            key={index}
            label={route.label}
            icon={<route.icon sx={{ fontSize: 28 }} />}
            sx={{
              "&.Mui-selected": {
                "& .MuiBottomNavigationAction-label": {
                  fontSize: "0.75rem",
                  transition: "font-size 0.2s, opacity 0.2s",
                  opacity: 1,
                  color: "#1976d2", // Selected text color
                },
                "& .MuiSvgIcon-root": {
                  transition: "transform 0.2s",
                  transform: "translateY(-4px)",
                },
              },
              "& .MuiBottomNavigationAction-label": {
                fontSize: "0.70rem",
                transition: "font-size 0.2s, opacity 0.2s",
                opacity: 0.7,
                color: "rgba(0, 0, 0, 0.6)",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}
