import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import EscanerQR from "./NuevoInventario/EscanerQR";
import BarcodeScanner from "./NuevoInventario/BarcodeScanner";
import { QrCode, Barcode, X, Check, AlertTriangle, XCircle, Loader2, RefreshCcw } from "lucide-react";

const InventoryPopup = ({ selectedItem, setShowPopup, setMessage, smartScannerActive }) => {
  const [scannerState, setScannerState] = useState({
    showQr: false,
    showBarcode: false,
  });
  const [manualCode, setManualCode] = useState("");
  const [inventoryData, setInventoryData] = useState(null);
  const [scannedCodes, setScannedCodes] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [customAlert, setCustomAlert] = useState({ 
    show: false, 
    message: "", 
    onConfirm: null,
    type: ""
  });
  const manualCodeInputRef = useRef(null);
  const scannedCodesRef = useRef(null);

  // Optimized focus management
  useEffect(() => {
    const focusInput = () => manualCodeInputRef.current?.focus();
    const intervalId = setInterval(focusInput, 100);
    return () => clearInterval(intervalId);
  }, []);

  // Enhanced auto-scroll
  useEffect(() => {
    if (scannedCodesRef.current) {
      scannedCodesRef.current.scrollTop = scannedCodesRef.current.scrollHeight;
    }
  }, [scannedCodes]);

  // Memoized snapshot listener
  useEffect(() => {
    if (!selectedItem?.id) return;

    const unsub = onSnapshot(doc(db, "inventory", selectedItem.id), (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setInventoryData({
          ...data,
          codigoAlta: Array.isArray(data.codigoAlta) ? data.codigoAlta : [],
          codigoBaja: Array.isArray(data.codigoBaja) ? data.codigoBaja : [],
          imageUrl: data.images?.[0]?.url || null
        });
      } else {
        setInventoryData({ codigoAlta: [], codigoBaja: [], imageUrl: null });
      }
    });
    return () => unsub();
  }, [selectedItem?.id]);

  // Optimized code status determination
  const getCodeStatus = useCallback((code) => {
    const isAltaRegistered = inventoryData?.codigoAlta?.some(item => item.codigo === code);
    const isBajaRegistered = inventoryData?.codigoBaja?.some(item => item.codigo === code);
    
    if (isAltaRegistered) return 'registered';
    if (isBajaRegistered) return 'inactive';
    return 'new';
  }, [inventoryData]);

  // Memoized handlers to prevent unnecessary re-renders
  const handleInventoryChange = useCallback(async () => {
    if (!inventoryData || scannedCodes.length === 0) {
      setCustomAlert({ 
        show: true, 
        message: "No hay códigos para procesar.",
        type: "error" 
      });
      return;
    }

    setProcessing(true);
    try {
      const codigoAlta = [...(inventoryData.codigoAlta || [])];
      const codigoBaja = [...(inventoryData.codigoBaja || [])];
      const timestamp = new Date().toISOString();

      scannedCodes.forEach(({ code, action }) => {
        if (action === "alta") {
          const indexBaja = codigoBaja.findIndex((item) => item.codigo === code);
          if (indexBaja !== -1) {
            codigoBaja.splice(indexBaja, 1);
          }
          if (!codigoAlta.some((item) => item.codigo === code)) {
            codigoAlta.push({ codigo: code, fechaAlta: timestamp });
          }
        } else if (action === "baja") {
          const indexAlta = codigoAlta.findIndex((item) => item.codigo === code);
          if (indexAlta !== -1) {
            codigoBaja.push({ codigo: code, fechaBaja: timestamp });
            codigoAlta.splice(indexAlta, 1);
          }
        }
      });

      await updateDoc(doc(db, "inventory", selectedItem.id), {
        codigoAlta,
        codigoBaja,
      });

      setMessage("Inventario actualizado correctamente");
      setScannedCodes([]);
    } catch (error) {
      console.error("Error al actualizar inventario:", error);
      setCustomAlert({ 
        show: true, 
        message: "Error al actualizar el inventario. Intente nuevamente.",
        type: "error" 
      });
    } finally {
      setProcessing(false);
    }
  }, [inventoryData, scannedCodes, selectedItem?.id, setMessage]);

  const handleScan = useCallback(
    (codigo) => {
      if (!codigo) return;

      const status = getCodeStatus(codigo);
      const existingIndex = scannedCodes.findIndex(item => item.code === codigo);

      setScannedCodes(prev => {
        if (existingIndex !== -1) {
          return prev.map((item, index) => 
            index === existingIndex 
              ? { ...item, action: item.action === 'alta' ? 'baja' : 'alta' }
              : item
          );
        }

        const newAction = status === 'registered' ? 'baja' : 'alta';
        return [...prev, { 
          code: codigo, 
          status,
          action: newAction,
          timestamp: new Date().toISOString()
        }];
      });
    },
    [getCodeStatus, scannedCodes]
  );

  const handleManualCodeSubmit = useCallback(() => {
    if (!manualCode.trim()) {
      setCustomAlert({ 
        show: true, 
        message: "El código no puede estar vacío.",
        type: "error" 
      });
      return;
    }
    handleScan(manualCode.trim());
    setManualCode("");
  }, [manualCode, handleScan]);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'registered':
        return <Check className="w-6 h-6 text-green-500" />;
      case 'inactive':
        return <AlertTriangle className="w-6 h-6 text-yellow-500" />;
      case 'new':
        return <XCircle className="w-6 h-6 text-red-500" />;
      default:
        return null;
    }
  };

  const getActionBadge = (action) => {
    const baseClasses = "px-2 py-1 rounded-full text-sm font-medium";
    return action === 'alta' 
      ? <span className={`${baseClasses} bg-green-100 text-green-800`}>Alta</span>
      : <span className={`${baseClasses} bg-red-100 text-red-800`}>Baja</span>;
  };

  // Nuevo método para reiniciar escaneos
  const handleResetScans = useCallback(() => {
    setScannedCodes([]);
    setMessage("Escaneos reiniciados");
  }, [setMessage]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-3xl p-6 max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-3xl font-bold text-gray-800">{inventoryData?.name || "Producto"}</h2>
          <button
            onClick={() => setShowPopup(false)}
            className="text-gray-500 hover:text-gray-700 p-2 rounded-full transition-colors"
          >
            <X className="w-8 h-8" />
          </button>
        </div>

        {/* Product Image */}
        {inventoryData?.imageUrl && (
          <div className="w-full h-32 overflow-hidden rounded-lg mb-4 flex-shrink-0">
            <img
              src={inventoryData.imageUrl}
              alt={inventoryData.name}
              className="w-full h-full object-contain"
            />
          </div>
        )}

        {/* Main Content - Scrollable */}
        <div className="flex-1 overflow-y-auto min-h-0">
          {/* Scanner Buttons */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <button
              onClick={() => setScannerState({ showQr: true, showBarcode: false })}
              className="flex items-center justify-center py-4 bg-green-500 text-white text-xl rounded-xl hover:bg-green-600 transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              <QrCode className="w-8 h-8 mr-3" /> QR
            </button>

            <button
              onClick={() => setScannerState({ showQr: false, showBarcode: true })}
              className="flex items-center justify-center py-4 bg-blue-500 text-white text-xl rounded-xl hover:bg-blue-600 transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              <Barcode className="w-8 h-8 mr-3" /> Código de Barras
            </button>
          </div>

          {/* Manual Input */}
          <div className="flex gap-2 mb-4 sticky top-0 bg-white z-10 py-2">
            <input
              type="text"
              ref={manualCodeInputRef}
              value={manualCode}
              onChange={(e) => setManualCode(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleManualCodeSubmit()}
              className="flex-1 px-4 py-3 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:outline-none text-lg"
              placeholder="Ingrese el código manual"
            />
            <button
              onClick={handleManualCodeSubmit}
              className="px-6 py-3 bg-blue-500 text-white rounded-xl hover:bg-blue-600 text-lg shadow-lg hover:shadow-xl transition-all duration-200 whitespace-nowrap"
            >
              Escanear
            </button>
          </div>
{/* Footer - Action Button */}
{scannedCodes.length > 0 && (
          <div className="flex gap-2 mt-4 pt-4 border-t sticky bottom-0 bg-white">
            <button
              onClick={handleInventoryChange}
              disabled={processing}
              className="flex-1 px-6 py-3 bg-green-500 text-white rounded-xl hover:bg-green-600 transition-all duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed text-lg font-medium"
            >
              {processing && <Loader2 className="w-5 h-5 animate-spin" />}
              {processing ? 'Procesando...' : `Actualizar Inventario (${scannedCodes.length})`}
            </button>
            <button
              onClick={handleResetScans}
              className="flex items-center justify-center bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
            >
              <RefreshCcw className="mr-2" /> Reiniciar Escaneos
            </button>
          </div>
        )}
          {/* Scanned Codes List */}
          {scannedCodes.length > 0 && (
            <div className="bg-gray-50 rounded-xl p-4">
              <h3 className="text-lg font-bold text-gray-700 mb-3 sticky top-0 bg-gray-50">
                Códigos Escaneados ({scannedCodes.length})
              </h3>
              <div ref={scannedCodesRef} className="space-y-2 max-h-[40vh] overflow-y-auto">
                {scannedCodes.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
                  >
                    <div className="flex items-center gap-3">
                      {getStatusIcon(item.status)}
                      <span className="text-gray-700 font-medium">{item.code}</span>
                    </div>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => handleScan(item.code)}
                        className="flex items-center gap-2 px-3 py-1 rounded-lg hover:bg-gray-100 transition-colors"
                      >
                        {getActionBadge(item.action)}
                      </button>
                      <button
                        onClick={() => setScannedCodes(prev => prev.filter((_, i) => i !== index))}
                        className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Scanners */}
          {scannerState.showQr && (
            <EscanerQR
              showQrScanner={scannerState.showQr}
              setShowQrScanner={(show) => setScannerState({ ...scannerState, showQr: show })}
              setNumInventario={handleScan}
              setMessage={setMessage}
            />
          )}

          {scannerState.showBarcode && (
            <BarcodeScanner
              setNumInventario={handleScan}
              setMessage={setMessage}
            />
          )}
        </div>

        

        {/* Alert Modal */}
        {customAlert.show && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className={`p-6 rounded-lg shadow-lg max-w-sm w-full mx-4 bg-white border-l-4 ${
              customAlert.type === 'success' ? 'border-green-500' :
              customAlert.type === 'warning' ? 'border-yellow-500' :
              'border-red-500'
            }`}>
              <p className="text-gray-800 text-xl text-center mb-6">{customAlert.message}</p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => setCustomAlert({ show: false, message: "", onConfirm: null, type: "" })}
                  className="px-6 py-3 bg-gray-300 text-gray-700 rounded-xl hover:bg-gray-400 transition-all duration-200 text-lg"
                  >
                    Cancelar
                  </button>
                  {customAlert.onConfirm && (
                    <button
                      onClick={() => {
                        customAlert.onConfirm();
                        setCustomAlert({ show: false, message: "", onConfirm: null, type: "" });
                      }}
                      className="px-6 py-3 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-all duration-200 text-lg"
                    >
                      Confirmar
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default React.memo(InventoryPopup);
  