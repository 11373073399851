import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import InventoryPopup from "./InventoryPopup";
import {
  Package,
  Tag,
  Bookmark,
  Calendar,
  Box,
  DollarSign,
  BarChart,
  ArrowUp,
  ArrowDown
} from "lucide-react";

const InventoryId = () => {
  const { uid } = useParams();
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [codigoAlta, setCodigoAlta] = useState([]);
  const [codigoBaja, setCodigoBaja] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCodigo, setSelectedCodigo] = useState(null);
  const [activeTab, setActiveTab] = useState("alta");

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productRef = doc(db, "inventory", uid);

        // Real-time listener for product data
        const unsubscribe = onSnapshot(productRef, async (snapshot) => {
          if (snapshot.exists()) {
            const productData = snapshot.data();
            setProduct(productData);

            if (productData.category) {
              const categoryDoc = await getDoc(doc(db, "categories", productData.category));
              if (categoryDoc.exists()) {
                setCategory(categoryDoc.data().name);
              }
            }

            if (productData.subcategory) {
              const subcategoryDoc = await getDoc(
                doc(db, `categories/${productData.category}/subcategories`, productData.subcategory)
              );
              if (subcategoryDoc.exists()) {
                setSubcategory(subcategoryDoc.data().name);
              }
            }

            if (productData.brand) {
              const brandDoc = await getDoc(doc(db, "brands", productData.brand));
              if (brandDoc.exists()) {
                setBrand(brandDoc.data().name);
              }
            }

            if (productData.model) {
              const modelDoc = await getDoc(
                doc(db, `brands/${productData.brand}/models`, productData.model)
              );
              if (modelDoc.exists()) {
                setModel(modelDoc.data().name);
              }
            }

            if (productData.codigoAlta) {
              setCodigoAlta(productData.codigoAlta);
            }
            if (productData.codigoBaja) {
              setCodigoBaja(productData.codigoBaja);
            }
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [uid]);

  const handleCodigoClick = (codigo) => {
    setSelectedCodigo(codigo);
    setShowPopup(true);
  };

  const totalAlta = codigoAlta.length;
  const totalBaja = codigoBaja.length;
  const totalCodigos = totalAlta + totalBaja;

  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="animate-pulse flex flex-col items-center space-y-4">
          <div className="w-48 h-48 bg-gray-200 rounded-lg"></div>
          <div className="h-8 w-64 bg-gray-200 rounded"></div>
          <div className="h-4 w-48 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        {/* Product Header Section */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
            {/* Product Image */}
            <div className="w-48 h-48 flex-shrink-0">
              {product.images && product.images.length > 0 ? (
                <img
                  src={product.images[0].url}
                  alt={product.name}
                  className="w-full h-full object-cover rounded-lg shadow-md"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-lg border-2 border-dashed border-gray-200">
                  <Package className="w-12 h-12 text-gray-400" />
                </div>
              )}
            </div>

            {/* Product Info */}
            <div className="flex-1 text-center md:text-left">
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                {product.name || "Sin Nombre"}
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="flex items-center gap-2">
                  <Tag className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-600">Categoría:</span>
                  <span className="font-medium">{category || "Sin Categoría"}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Bookmark className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-600">Subcategoría:</span>
                  <span className="font-medium">{subcategory || "Sin Subcategoría"}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Box className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-600">Marca:</span>
                  <span className="font-medium">{brand || "Sin Marca"}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Package className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-600">Modelo:</span>
                  <span className="font-medium">{model || "Sin Modelo"}</span>
                </div>
              </div>
              <div className="flex flex-wrap justify-center md:justify-start gap-6 mt-6">
                <div className="bg-blue-50 px-4 py-2 rounded-lg">
                  <div className="flex items-center gap-2">
                    <BarChart className="w-5 h-5 text-blue-500" />
                    <span className="text-blue-600 font-semibold">Stock: {product.stock}</span>
                  </div>
                </div>
                <div className="bg-green-50 px-4 py-2 rounded-lg">
                  <div className="flex items-center gap-2">
                    <DollarSign className="w-5 h-5 text-green-500" />
                    <span className="text-green-600 font-semibold">
                      Precio: ${product.price.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Códigos Tabs Section */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4 sm:mb-0">Códigos de Inventario</h2>
            <div className="flex gap-2">
              <button
                onClick={() => setActiveTab("alta")}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                  activeTab === "alta"
                    ? "bg-blue-100 text-blue-700"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
              >
                <ArrowUp className="w-4 h-4" />
                Alta ({totalAlta})
              </button>
              <button
                onClick={() => setActiveTab("baja")}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                  activeTab === "baja"
                    ? "bg-blue-100 text-blue-700"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
              >
                <ArrowDown className="w-4 h-4" />
                Baja ({totalBaja})
              </button>
            </div>
          </div>

          {/* Códigos Content */}
          <div className="mt-6">
            {activeTab === "alta" && (
              <div>
                {codigoAlta.length > 0 ? (
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {codigoAlta.map((codigo, index) => (
                      <div
                        key={index}
                        onClick={() => handleCodigoClick(codigo.codigo)}
                        className="bg-gray-50 p-4 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
                      >
                        <div className="flex items-center gap-2 mb-2">
                          <Tag className="w-4 h-4 text-blue-500" />
                          <span className="font-medium text-blue-600">{codigo.codigo}</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                          <Calendar className="w-4 h-4" />
                          <span>{new Date(codigo.fechaAlta).toLocaleString()}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-center py-8">No hay códigos de alta disponibles.</p>
                )}
              </div>
            )}

            {activeTab === "baja" && (
              <div>
                {codigoBaja.length > 0 ? (
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {codigoBaja.map((codigo, index) => (
                      <div
                        key={index}
                        onClick={() => handleCodigoClick(codigo.codigo)}
                        className="bg-gray-50 p-4 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
                      >
                        <div className="flex items-center gap-2 mb-2">
                          <Tag className="w-4 h-4 text-red-500" />
                          <span className="font-medium text-red-600">{codigo.codigo}</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                          <Calendar className="w-4 h-4" />
                          <span>{new Date(codigo.fechaBaja).toLocaleString()}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-center py-8">No hay códigos de baja disponibles.</p>
                )}
              </div>
            )}
          </div>

          {/* Totals Summary */}
          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="text-sm text-blue-600">Total Códigos de Alta</div>
                <div className="text-2xl font-bold text-blue-700">{totalAlta}</div>
              </div>
              <div className="bg-red-50 p-4 rounded-lg">
                <div className="text-sm text-red-600">Total Códigos de Baja</div>
                <div className="text-2xl font-bold text-red-700">{totalBaja}</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600">Total General</div>
                <div className="text-2xl font-bold text-gray-700">{totalCodigos}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && selectedCodigo && (
        <InventoryPopup
          selectedItem={{ codigo: selectedCodigo }}
          setShowPopup={setShowPopup}
        />
      )}
    </div>
  );
};

export default InventoryId;
