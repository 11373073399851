import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="w-full bg-white py-12 px-4 sm:py-16 md:py-20"
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col items-center">
          {/* Image Container */}
          <div className="w-full max-w-2xl mb-8 px-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/patrullas-electricas-de-la-policia-de-hermosillo-de405736-focus-0-0-1300-866-900w.jpg?alt=media&token=fe2570ac-9c43-44fb-9b33-e437b351b149"
              alt="Patrullas eléctricas de la policía de Hermosillo"
              className="w-full h-auto rounded-2xl shadow-lg object-cover"
            />
          </div>

          {/* Text Content */}
          <div className="w-full max-w-3xl px-4">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-6 text-center">
              Tecnología de punta
            </h2>

            <p className="text-base sm:text-lg md:text-xl text-gray-700 leading-relaxed text-center">
              Contamos con acceso a las mejores tecnologías disponibles en el
              mercado actual. Gracias a ello, podemos equipar nuestras unidades
              con equipos de vanguardia para garantizar un rendimiento óptimo.
              Nuestro compromiso con la excelencia nos impulsa a utilizar lo
              último en tecnología para brindar un servicio de calidad superior
              a nuestros clientes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
